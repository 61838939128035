import * as client_hooks from '../../../src/hooks.client.ts';

export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42')
];

export const server_loads = [0];

export const dictionary = {
		"/": [2],
		"/about": [3],
		"/admin/categorise": [~4],
		"/admin/categorise/[dataImportIdOrAll]": [~5],
		"/admin/ocr": [~6],
		"/admin/tools": [~7],
		"/app": [~8],
		"/app/claim/[invitationId=integer]": [~9],
		"/app/claim/[invitationId=integer]/[claimToken]": [~10],
		"/app/common/[productId=integer]": [~11],
		"/app/help": [12],
		"/app/ocr/[productId=integer]": [~13],
		"/app/paid/[productId=integer]": [~14],
		"/app/partn/new": [~16],
		"/app/partn/[productId=integer]": [~15],
		"/app/reject/[invitationId=integer]/[claimToken]": [~17],
		"/app/report/new": [~19],
		"/app/report/[productId=integer]": [~18],
		"/app/settings": [~20],
		"/app/settings/payments": [~21],
		"/app/settings/payments/success": [~22],
		"/blog/affordable-forensic-accounting-family-law": [~23],
		"/blog/forensic-account-for-self-represented-family-law-litigants": [~24],
		"/categories": [~25],
		"/compliance": [26],
		"/dev/testimonial": [27],
		"/faq": [28],
		"/financial-statement-kit": [29],
		"/integration/code": [~30],
		"/learn/partn": [31],
		"/learn/report": [32],
		"/legals": [33],
		"/login": [34],
		"/login/badlink": [36],
		"/login/[token=uuid]": [~35],
		"/ocr": [37],
		"/quiz": [38],
		"/register": [~39],
		"/support": [40],
		"/support/smokeball": [41],
		"/survey/[type=productType]": [42]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';